import React, { useEffect, useState, useRef } from "react";
import ChartView from "../Charts/ChartView";
import Button from "components/CustomButton/CustomButton.jsx";
import { get_data } from "../../models/api";
import moment from "moment";

import cyanrabbit from "assets/img/rabbit-cyan-250x207.png"; //tgs
import cyanturtle from "assets/img/turtle-cyan-250-163.png"; //tgs
import grayrabbit from "assets/img/rabbit_gray_250x207.png"; //tgs
import grayturtle from "assets/img/turtle_gray_250-163.png"; //tgs
import disengage from "assets/img/disengage-btn.jpg"; //tgs
import disengageactive from "assets/img/disengage-gray-btn.jpg"; //tgs
import { fetch_watertransfer } from "../../models/api";
import { send_stopped } from "../../models/api";
import { send_running } from "../../models/api";
import { send_throttle } from "../../models/api";
import { send_off } from "../../models/api";
import { send_maxpressure } from "../../models/api";
import { setDeviceListUpdate, setDeviceUpdate } from "../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { detailed_update } from "../../models/api";

import {
  Grid,
  Row,
  Col,
  Table,
  PanelGroup,
  Panel,
  Tab,
  Tabs,
} from "react-bootstrap";

let wtMode = "";
let wtState = "";
//let wtInterval = 50000;
let wtLastCmdState = "";
let wtLastCmdAckd = "";
let wtLimitDischarge = 0;
let wtLastCmdTgtRpm = 0;
let wtReadingTgtRpm = 0;
let wtLastCmdDischargeValue = 0;
let wtReadingOffsetRpm = 0;
let wtReadingLocalRpmReq = 0;
let wtReadingEngRpm = 0;
let wtReadingDischarge = 0;
let wtReadingSuction = 0;
let wtReadingFuelLvl = 0;
let wtReadingRate = 0;
let rpmValue = 0;
let stopping = false;
let starting = false;
let enabled = false;
let rpm = 0;
let lsThrottlePct = 0;
let lsThrottleRpm = 0;
let maxPressureValue = 0;
let wtRunningCmd = "";
let engine = "";
let transmission = "";
let ignoreFaultCount = 0; // ignoreCount == how many 


const shouldShowJ1939 = (device) => {
  const hasJ1939 = device.j1939Time && device.j1939Snapshot;
  const hasTricon = !!device.triconTime;
  return (
    hasJ1939 &&
    (!hasTricon || device.j1939Time.diff(device.triconTime, "hours") > -2)
  );
};


const SelectedDevice = ({ currentPage }) => {
  const dispatch = useDispatch();
  const selectedDevice = useSelector((state) => state.data.selectedDevice)[0];
  const deviceUpdate = useSelector((state) => state.data.deviceUpdate);

  const [device, setDevice] = useState(selectedDevice);
  const [deviceUpdateNum, setDeviceUpdateNum] = useState(0);

  const [topLoadPercentValue, setTopLoadPercentValue] = useState("--");
  const [topDischargePsiValue, setTopDischargePsiValue] = useState("--");
  const [topSuctionPsiValue, setTopSuctionPsiValue] = useState("--");
  const [topRateBpmValue, setTopRateBpmValue] = useState("--");
  const [topRpmValue, setTopRpmValue] = useState("--");
  const [topFuelPsiValue, setTopFuelPsiValue] = useState("--");

  // MUSTANG_RW -- This was done to create clean separation (like the ones in DeviceList)
  const [isMustangRW, setIsMustangRW] = useState(false);
  const [topRWRPM, setTopRWRPM] = useState("--")                   // Button 1
  const [topRWLoadPercent, setTopRWLoadPercent] = useState("--");  // Button 2
  const [topRWFuelPSI, setTopRWFuelPSI] = useState("--")           // Button 3
  const [topRWCoolantTemp, setTopRWCoolantTemp] = useState("--");  // Button 4
  const [topRWOilPressure, setTopRWOilPressure] = useState("--");  // Button 5
  const [topRWFuelRate, setTopRWFuelRate] = useState("--");        // Button 6

  const [dataName, setDataName] = useState(null);
  const [isMustang, setIsMustang] = useState(false);
  const [isKind, setIsKind] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [resetKey, setResetKey] = useState(0);
  const [stopBtnState, setStopBtnState] = useState(false);
  const [stoppingBtnState, setStoppingBtnState] = useState(false);
  const [enableBtnState, setEnableBtnState] = useState(false);
  const [enableBtnDisabled, setEnableBtnDisabled] = useState(true);
  const [startBtnState, setStartBtnState] = useState(false);
  const [startingBtnState, setStartingBtnState] = useState(false);
  const [disengageBtnState, setDisengageBtnState] = useState(false);
  const [maxPressBtnDisabled, setMaxPressBtnDisabled] = useState(true);
  const [maxPressBtnSelected, setMaxPressBtnSelected] = useState(false);
  const [throttleDisabled, setThrottleDisabled] = useState(true);
  const [sliderLabelClass, setSliderLabelClass] = useState("slider-label-disabled");
  const [isAutoGreen, setIsAutoGreen] = useState(true);
  const [isOnGreen, setIsOnGreen] = useState(false);
  const [isRunningGreen, setIsRunningGreen] = useState(false);
  const [turtleImage, setTurtleImage] = useState(grayturtle);
  const [rabbitImage, setRabbitImage] = useState(grayrabbit);
  const [resultsAlert, setResultsAlert] = useState(null);
  // const [inputValue, setInputValue] = useState('');
  // const [storedValue, setStoredValue] = useState('');
  // detail interval value = useState(30000); Scott Sr
  const [detailInterval, setDetailInterval] = useState(30000);  // 30000 = 30 seconds. Modify this to test... Maybe crank it way up to pause the auto refresh.

  const [globalDev, setGlobalDev] = useState({}); // Assuming globalDev state setup
  const [selectedTab, setSelectedTab] = useState(null); // State to track the selected tab
  const [ignoreCount, setIgnoreCount] = useState(0);
  
  // water transfer interval
  const wtInterval = 10000; // Interval duration
  const wtIntervalIdRef = useRef(null); // Ref to store the interval ID
  

  

  const needsAttnCriteria = {
    'ENGINE RPM': () => ({ value: parseFloat(engine[0].value), threshold: 10 }),
    'ENGINE OIL PRESSURE': () => ({ value: parseFloat(engine[1].value), threshold: 10 }),
    'ENGINE COOLANT TEMP': () => ({ value: parseFloat(engine[2].value), threshold: 10 }),
    'ENGINE FUEL TEMP': () => ({ value: parseFloat(engine[3].value), threshold: 10 }),
    'ENGINE FUEL RATE': () => ({ value: parseFloat(engine[4].value), threshold: 1 }),
    'ENGINE FUEL PRESSURE': () => ({ value: parseFloat(engine[5].value), threshold: 1 }),
    'ENGINE INTAKE MANIFOLD PRESSURE': () => ({ value: parseFloat(engine[6].value), threshold: 10 }),
    'ENGINE INTAKE MANIFOLD TEMP': () => ({ value: parseFloat(engine[7].value), threshold: 10 }),
    'ENGINE VOLTAGE': () => ({ value: parseFloat(engine[8].value), threshold: 10 }), // Corrected typo 'VOLATAGE' to 'VOLTAGE'
    'ENGINE LOAD%': () => ({ value: parseFloat(engine[9].value), threshold: 10 }),
    'ENGINE HOURS': () => ({ value: parseFloat(engine[10].value), threshold: 10 }),
    'ENGINE THROTTLE': () => ({ value: parseFloat(engine[11].value), threshold: 10 }),
    'TRANSMISSION OIL PRESSURE': () => ({ value: parseFloat(transmission[0].value), threshold: 10 }),
    'TRANSMISSION OIL TEMP': () => ({ value: parseFloat(transmission[1].value), threshold: 10 })
  };

  const handleClear  = () => {
    console.log("remember to do the clear issue function");
  }

  useEffect(() => {
    console.log("useEffect--deviceUpdateNum: " + deviceUpdateNum + " ---ignoreCount: " + ignoreCount);   
    handleTabSelect();
  }, [deviceUpdateNum,ignoreCount]);
  
  useEffect(() => {
    console.log("Updated slider value: ", sliderValue);   
    
  }, [sliderValue]);


  useEffect(() => {
    const fetchAndHandleData = async () => {
      if (!globalDev.id || selectedTab !== "auto") {
        return; // Exit if globalDev.id is not set or the selected tab is not "auto"
      }

      try {
        const wtData = await fetch_watertransfer(globalDev.id);
        handleWTUI(wtData);
      } catch (error) {
        console.error("Error fetching water transfer data:", error);
      }
    };

    if (selectedTab === "auto") {
      fetchAndHandleData(); // Perform initial fetch
      clearInterval(wtIntervalIdRef.current); // Clear any existing interval
      wtIntervalIdRef.current = setInterval(fetchAndHandleData, wtInterval); // Set new interval
    }

    // Cleanup function
    return () => {
      clearInterval(wtIntervalIdRef.current);
    };
  }, [selectedTab, globalDev.id]);


  useEffect(() => {
    console.log('detail');
    // if (!globalDev.id || selectedTab !== "detail") {
    //   return; // Exit if globalDev.id is not set or the selected tab is not "auto"
    // }

    let interval;
    if (deviceUpdate) {
      getDetails();

      // Set the interval for every subsequent minute
      interval = setInterval(() => {
        console.log("INTERVAL REFRESH");
        // handleDataTab();
        setDeviceUpdateNum((currentValue) => currentValue + 1);
        getDetails();
      }, detailInterval); //wt -  60000 milliseconds = 1 minute

      // Clear interval on component unmount
      return () => {
        clearInterval(interval);
      };
    } else {
      clearInterval(interval);
    }
  }, [selectedTab, deviceUpdate]);

  useEffect(() => {
    // This assumes that `device` contains the latest `j1939Snapshot` information   
    setResultsAlert(evalForNeedsAttn()); // This will update the state and trigger a re-render of ResultsDisplay
  }, [device, selectedTab, isMustangRW]); // Add dependencies that should trigger this effect


  const getDetails = async () => {
    //Update the device details
    detailed_update(device).then((dev) => {
      setDevice(dev);
      setIsMustang(dev.name.toLowerCase().includes("mustang"));
      console.log("isMustang " + isMustang);
      console.log("value of kind = " + dev.kind);
      setIsKind(dev.kind === "MUSTANG_RW");
      console.log("isKind " + isKind);
      setGlobalDev(dev); //wt

      // MUSTANG_RW specifically
      if (dev.isMustangRW) {

        setIsMustangRW(true);
        // setResultsAlert(evalForNeedsAttn());

        setTopRWRPM(dev.prop_jrpm);
        setTopRWLoadPercent(dev.prop_jloadPercent);
        setTopRWFuelPSI(dev.prop_jfuelpsi);
        setTopRWCoolantTemp(dev.prop_jcoolant);
        setTopRWOilPressure(dev.prop_joil);
        setTopRWFuelRate(dev.prop_jfuelrate);

      } else {
        setIsMustangRW(false);

        //Here we are setting the top button values for j1939
        if (dev.j1939Snapshot.spnsAry.engine) {
          const rpmSpn = device.j1939Snapshot.spnsAry.engine.find(
            (spn) => spn.name === "RPM"
          );
          if (rpmSpn) {
            setTopRpmValue(rpmSpn.value);
          }

          const fuelPsiSpn = dev.j1939Snapshot.spnsAry.engine.find(
            (spn) => spn.name === "FUEL PRESSURE"
          );

          if (fuelPsiSpn) {
            setTopFuelPsiValue(fuelPsiSpn.value);
          }

          const loadPercentSpn = dev.j1939Snapshot.spnsAry.engine.find(
            (spn) => spn.name === "LOAD %"
          );

          if (loadPercentSpn) {
            setTopLoadPercentValue(loadPercentSpn.value);
          }
        }

        //Here we are setting the top button values for devices with sections
        //I think this would be Tricon
        if (device.sections.length > 0) {
          device.sections.forEach((section) => {
            section.values.forEach((value) => {
              //Engine section
              if (section.name === "Engine") {
                if (value.name === "RPM") {
                  setTopRpmValue(value.text);
                }
                if (value.name === "LOAD_PERCENT") {
                  setTopLoadPercentValue(value.text);
                }
              }

              //Pump section
              if (section.name === "Pump") {
                if (value.name === "OUT_PRESS_PSI") {
                  setTopDischargePsiValue(value.text);
                }
                if (value.name === "SUCTION_PRESS_PSI") {
                  setTopSuctionPsiValue(value.text);
                }
                if (value.name === "RATE_BPM") {
                  setTopRateBpmValue(value.text);
                }
              }
            });
          });
        }
      }
    });
  };

  const handleDataTab = () => {  //wt  
    setSelectedTab("data");
    setResultsAlert(evalForNeedsAttn());
  };
  

const  ignoreItem = (key) => {
  // Replace this logic later with the gql query result
 
  return Boolean(sessionStorage.getItem(key));
}

const evalForNeedsAttn = () => {
  if (isMustang) {
    if (device.j1939Snapshot) {
      engine = device.j1939Snapshot.spnsAry.engine;
      transmission = device.j1939Snapshot.spnsAry.transmission;
      console.table( engine);
      console.table( transmission);

      const results = {};
      for (const key in needsAttnCriteria) {
        if (!ignoreItem(key)) {
          //crashes when engine is undefined; bypassing since the logic isn't hooked up
          const { value, threshold } = {value: 0.0, threshold: 10.0}; //needsAttnCriteria[key](); 
          results[key] = {
            value: value,
            threshold: threshold,
            isExceeding: value > threshold // This will hold the boolean value
          };
        }
      }
      return results;
    }
  }
}

  const ignoreFault = (key) => {
    console.log("Ignoring fault:", key);
    setIgnoreCount(currentValue => currentValue+1); // trigger refresh in useEffect
    sessionStorage.setItem(key, "ignore")

    // Add additional logic for what should happen when a fault is ignored
  };

  const ResultsDisplay = ({ results }) => {
    // Convert the results object into an array of [key, value] pairs for easy mapping

    // Switch OFF the Needs Attention Logic uncommment this:
     return (<div></div>)    

    if (!results) {
      return (<div></div>)
    }
    
    const isAnyExceeding = Object.values(results).some(criteria => criteria.isExceeding);

    if (!isAnyExceeding) {
      return (<div></div>)  // could put an alll clear message or some such here. 
    }

    const entries = Object.entries(results);
  
    // Filter the entries to only those where 'isExceeding' is true
    const exceedingEntries = entries.filter(([_, value]) => value.isExceeding);
  
    const tableStyle = {
      width: '100%',
      borderCollapse: 'collapse',
    };
  
    const cellStyle = {
      border: '1px solid black',
      padding: '5px',
      textAlign: 'left',
    };
  
    const valueCellStyle = {
      ...cellStyle,
      color: 'red', // This will make the text color red
    };
  
    return (
      <div className="needs-attention-heading">
        <span> NEEDS ATTENTION </span>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={cellStyle}>Fault</th>
              {/* <th style={cellStyle}>Threshold</th> */}
              <th style={cellStyle}>Value</th>
              {/* <th style={cellStyle}>Ignore</th> */}
            </tr>
          </thead>
          <tbody className="needs-attention-data">
            {exceedingEntries.map(([key, value]) => (
              <tr key={key}>
                <td style={cellStyle}>{key}</td>
                {/* <td style={cellStyle}>{value.threshold.toFixed(1)}</td> */}
                <td style={valueCellStyle}>{value.value.toFixed(1)}</td>
                {/* <td style={cellStyle}>
                  <button onClick={() => ignoreFault(key)}>Ignore</button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <hr style={{ marginTop: 20 }}></hr>
      </div>
    );
  };

  const handleAutoTab = () => {  //wt  
    setSelectedTab("auto");
  };

  const handleWTUI = (wtData) => {
    console.log("****** HANDELING WT UI *****");
    if (!wtData.mode) {
      wtMode = "Unavailable";
      console.log("!!!! MODE: " + wtMode);
      console.log("*****wtData.mode: " + wtData.mode);
    } else {
      wtMode = wtData.mode;
      console.log("!!!! MODE: " + wtMode);
      console.log("*****wtData.mode: " + wtData.mode);
      if (wtMode !== "AUTOMATIC") {
        handleManualMode();
        return;
      }
    }

    wtRunningCmd = wtData.runningCommand;
    console.log("!!!!!!RunningCommand: " + wtRunningCmd);

    if (!wtData.state) {
      wtState = "Unavailable";
      console.log("!!!! STATE: " + wtState);
      console.log("*****wtData.state: " + wtData.state);
    } else {
      wtState = wtData.state;
      console.log("!!!! STATE: " + wtState);
      console.log("*****wtData.state: " + wtData.state);
    }

    if (!wtData.lastCmdState) {
      wtLastCmdState = "Unavailable";
      console.log("!!!! Last Cmd: " + wtData.lastCmdState);
    } else {
      wtLastCmdState = wtData.lastCmdState;
      console.log("!!!! Last Cmd: " + wtData.lastCmdState);
    }

    if (!wtData.lastCmdAckd) {
      wtLastCmdAckd = "Unavailable";
      console.log("!!!! last Cmd Stat Ackd: " + wtData.lastCmdAckd);
    } else {
      wtLastCmdAckd = wtData.lastCmdAckd;
      console.log("!!!! last Cmd Stat Ackd: " + wtData.lastCmdAckd);
    }

    if (!wtData.lastCmdTgtRpm) {
      wtLastCmdTgtRpm = 0;
      console.log("!!!! Last Cmd Target RPM Value: " + wtData.lastCmdTgtRpm);
    } else {
      wtLastCmdTgtRpm = wtData.lastCmdTgtRpm;
      console.log("!!!! Last Cmd Target RPM Value: " + wtData.lastCmdTgtRpm);
    }

    if (!wtData.lastCmdLmtDischarge) {
      wtLastCmdDischargeValue = 0;
      console.log("!!!! Limit Discharge Value: " + 0);
    } else {
      wtLastCmdDischargeValue = wtData.lastCmdLmtDischarge;
      console.log("!!!! Limit Discharge Value: " + wtLastCmdDischargeValue);
    }

    if (!wtData.readingTgtRpm) {
      wtReadingTgtRpm = 0;
      console.log("!!!!!! Reading Target RPM Value: " + 0);
    } else {
      wtReadingTgtRpm = wtData.readingTgtRpm;
      console.log("!!!!!! Reading Target RPM Value: " + wtData.readingTgtRpm);
    }

    if (!wtData.readingOffsetRpm) {
      wtReadingOffsetRpm = 0;
      console.log("!!!! Reading Offset RPM Value: " + 0);
    } else {
      wtReadingOffsetRpm = wtData.readingOffsetRpm;
      console.log("!!!! Reading Offset RPM Value: " + wtData.readingOffsetRpm);
    }

    if (!wtData.readingEngRpm) {
      wtReadingEngRpm = 0;
      console.log("!!!! Reading Engine RPM Value: " + 0);
    } else {
      wtReadingEngRpm = wtData.readingEngRpm;
      console.log("!!!! Reading Engine RPM Value: " + wtData.readingEngRpm);
    }

    if (!wtData.readingLocalRpmReq) {
      wtReadingLocalRpmReq = 0;
      console.log("!!!! Reading Local RPM Req Value: " + 0);
    } else {
      wtReadingLocalRpmReq = wtData.readingLocalRpmReq;
      console.log(
        "!!!! Reading Local RPM Req Value: " + wtData.readingLocalRpmReq
      );
    }

    if (!wtData.readingDischarge) {
      wtReadingDischarge = 0;
      console.log("!!!! Reading Discharge Value: " + 0);
    } else {
      wtReadingDischarge = wtData.readingDischarge;
      console.log("!!!! Reading Discharge Value: " + wtData.readingDischarge);
    }

    if (!wtData.readingSuction) {
      wtReadingSuction = 0;
      console.log("!!!! Reading Suction Value: " + 0);
    } else {
      wtReadingSuction = wtData.readingSuction;
      console.log("!!!! Reading Suction Value: " + wtData.readingSuction);
    }

    if (!wtData.readingRate) {
      wtReadingRate = 0;
      console.log("!!!!Reading Rate Value: " + 0);
    } else {
      wtReadingRate = wtData.readingRate;
      console.log("!!!!Reading Rate Value: " + wtData.readingRate);
    }

    if (!wtData.readingFuelLvl) {
      wtReadingFuelLvl = 0;
      console.log("!!!!Reading Fuel Level Value: " + 0);
    } else {
      wtReadingFuelLvl = wtData.readingFuelLvl;
      console.log("!!!!Reading Fuel Level Value: " + wtData.readingFuelLvl);
    }



    if (wtLastCmdState === "STOPPED") {
      enabled = true;
    } else {
      enabled = false;
    }

    if (wtMode === "AUTOMATIC" && wtRunningCmd === null) {
      enabled = false;
      handleManualState();
    } else if (wtMode === "AUTOMATIC" && wtState === "MANUAL" && !enabled) {
      handleManualState();
    } else if (wtMode === "AUTOMATIC" && wtState === "RUNNING" && stopping === false) {
      handleRunningState();
    } else if (wtMode === "AUTOMATIC" && wtState === "RUNNING" && stopping === true) {
      handleStoppingState();
    } else if (wtMode === "AUTOMATIC" && wtState === "STARTING") {
      handleStartingState();
    } else if (wtMode === "AUTOMATIC" && wtState === "OFF") {
      enabled = false;
      handleManualState();
    } else if (wtMode === "AUTOMATIC" && wtState === "STOPPED" && starting === false && enabled === true) {
      handleStoppedState();
    } else if (wtMode === "AUTOMATIC" && wtState === "STOPPED" && starting === true && enabled === false) {
      handleStartingState();
    }
  }

  //---mode === manual - disable all
  const handleManualMode = () => {
    console.log("HANDLE MANUAL MODE ");
    stopping = false;
    starting = false;
    enabled = false;

    //handleSliderChange()
    //led lights
    setIsAutoGreen(false);
    setIsRunningGreen(false);
    setIsOnGreen(false);
    //lg btn
    setStopBtnState(false);
    setStoppingBtnState(false);
    setEnableBtnDisabled(true);
    setEnableBtnState(false);
    setStartBtnState(false);
    setStartingBtnState(false);
    //disengage btn
    setDisengageBtnState(false);
    //settings btns
    setMaxPressBtnSelected(false);
    setMaxPressBtnDisabled(true);
    //throttle
    setThrottleDisabled(true);
    setTurtleImage(grayturtle);
    setRabbitImage(grayrabbit);
    setSliderLabelClass("slider-label-disabled");
    rpmValue = 0;
  };

  //---mode === automatic && state === manual || 0ff
  const handleManualState = () => {
    console.log("HANDLE MANUAL STATE ");
    stopping = false;
    starting = false;
    enabled = false;

    //led lights
    setIsAutoGreen(true);
    setIsRunningGreen(false);
    setIsOnGreen(false);
    //lg btn
    setStopBtnState(false);
    setStoppingBtnState(false);
    setEnableBtnDisabled(false);
    setEnableBtnState(true);
    setStartBtnState(false);
    setStartingBtnState(false);
    //disengage
    setDisengageBtnState(false);
    //settings btns
    setMaxPressBtnSelected(false);
    setMaxPressBtnDisabled(true);
    //throttle
    setThrottleDisabled(true);
    setTurtleImage(grayturtle);
    setRabbitImage(grayrabbit);
    setSliderLabelClass("slider-label-disabled");

  };

  //---mode === automatic && state === stopped
  const handleStoppedState = () => {
    console.log("HANDLE STOPPED STATE");
    stopping = false;
    starting = false;
    enabled = true;
    console.log("Enabled is: " + enabled);
    console.log("stopping is: " + stopping);
    console.log("starting is: " + starting);

    //led lights
    setIsAutoGreen(true);
    setIsOnGreen(true);
    setIsRunningGreen(false);
    //lg btn
    setStopBtnState(false);
    setStoppingBtnState(false);
    setEnableBtnState(false);
    setEnableBtnDisabled(false);
    setStartBtnState(true);
    setStartingBtnState(false);
    //disengage btn
    setDisengageBtnState(true);
    //settings btns
    setMaxPressBtnDisabled(true);
    setMaxPressBtnSelected(false);
    //throttle
    setThrottleDisabled(true);
    setTurtleImage(grayturtle);
    setRabbitImage(grayrabbit);
    setSliderLabelClass("slider-label-disabled");
    rpmValue = 0;
  };

  //---mode === automatic && state === starting
  const handleStartingState = () => {
    console.log("HANDLE STARTING STATE");
    stopping = false;
    starting = true;
    //led lights
    setIsAutoGreen(true);
    setIsOnGreen(true);
    setIsRunningGreen(false);
    //lg btn
    setStopBtnState(false);
    setStoppingBtnState(false);
    setEnableBtnState(false);
    setEnableBtnDisabled(false);
    setStartBtnState(false);
    setStartingBtnState(true);
    //disengage btn
    setDisengageBtnState(true);
    //settings btns
    setMaxPressBtnDisabled(true);
    setMaxPressBtnSelected(false);
    //throttle
    setThrottleDisabled(true);
    setTurtleImage(grayturtle);
    setRabbitImage(grayrabbit);
    setSliderLabelClass("slider-label-disabled");
    rpmValue = 600;
  };

  //---mode === automatic && state === running  
  const handleRunningState = () => {
    console.log("HANDLE RUNNING STATE");
    stopping = false;
    starting = false;
    lsThrottleRpm = localStorage.getItem(globalDev.id + "_throttle_rpm");


    if (lsThrottleRpm !== wtLastCmdTgtRpm) {
      rpmValue = wtLastCmdTgtRpm;
      localStorage.setItem(globalDev.id + "_throttle_rpm", rpmValue);
      localStorage.setItem(globalDev.id + "_throttle_pct", Math.round((wtLastCmdTgtRpm - 600) / 15));
    }

    lsThrottleRpm = localStorage.getItem("globalDev.id + _throttle_rpm");
    if (lsThrottleRpm) {
      rpmValue = lsThrottleRpm;
    }

    lsThrottlePct = localStorage.getItem(globalDev.id + "_throttle_pct");
    if (lsThrottlePct) {
      resetSlider(lsThrottlePct);
    }

    if (wtLastCmdDischargeValue === 50) {
      setMaxPressBtnSelected(false);
    }

    if (wtLastCmdDischargeValue === 85) {
      setMaxPressBtnSelected(true);
    }

    //led lights
    setIsAutoGreen(true);
    setIsOnGreen(true);
    setIsRunningGreen(true);
    //lg btn
    setStopBtnState(true);
    setStoppingBtnState(false);
    setEnableBtnState(false);
    setEnableBtnDisabled(false);
    setStartBtnState(false);
    setStartingBtnState(false);
    //disengage btn
    setDisengageBtnState(false);
    //settings btns
    setMaxPressBtnDisabled(false);

    //throttle
    setThrottleDisabled(false);
    setTurtleImage(cyanturtle);
    setRabbitImage(cyanrabbit);
    setSliderLabelClass("slider-label");
    rpmValue = rpmValue;
  };




  //---mode === automatic && state === running after Stop selected 
  const handleStoppingState = () => {
    console.log("------HANDLE STOPPING STATE-------");
    stopping = true;
    starting = false;
    localStorage.setItem(globalDev.id + "_throttle_pct", 0);
    localStorage.setItem(globalDev.id + "_throttle_rpm", 0);


    rpmValue = 0;

    //led lights
    setIsAutoGreen(true);
    setIsOnGreen(true);
    setIsRunningGreen(true);
    //lg btn
    setStopBtnState(false);
    setStoppingBtnState(true);
    setEnableBtnState(false);
    setEnableBtnDisabled(false);
    setStartBtnState(false);
    setStartingBtnState(false);
    //disengage btn
    setDisengageBtnState(false);
    //settings btns
    setMaxPressBtnDisabled(true);
    setMaxPressBtnSelected(false);
    //throttle
    setThrottleDisabled(true);
    setTurtleImage(grayturtle);
    setRabbitImage(grayrabbit);
    setSliderLabelClass("slider-label-disabled");

  };

  const handleStopBtnClick = (stopBtn) => {
    console.log("Button clicked: STOP");
    starting = false;
    stopping = true;

    localStorage.setItem(globalDev.id + "_throttle_pct", 0);
    localStorage.setItem(globalDev.id + "_throttle_rpm", 0);

    resetSlider();
    handleStoppingState();

    send_off(parseInt(globalDev.id)).then((stopped) => {
      // debugger;
    });
  };

  const handleEnableBtnClick = (enableBtn) => {
    console.log("*******Button clicked: ENABLE");
    let enabled = true;  //tgs
    let starting = false;  //tgs

    handleStoppedState();
    send_stopped(parseInt(globalDev.id)).then((stopped) => {
      // debugger;
    });

  }

  const handleStartBtnClick = (startBtn) => {
    // Perform actions here, such as changing state or initiating a GraphQL mutation
    console.log("******Button clicked: START");
    starting = true;
    stopping = false;

    handleStartingState();
    send_running(parseInt(globalDev.id)).then((running) => {
      // debugger;
    });

  }

  const handleDisengageBtnClick = (disengageBtn) => {
    console.log("********Button clicked: DISENGAGE ENGINE");
    handleManualState();
    send_off(parseInt(globalDev.id)).then((stopped) => {
      // debugger; 
    });

  };


  const handleMaxPressBtnClick = () => {
    let userConfirmed = false;

    if (wtLastCmdDischargeValue === 50) {
      userConfirmed = window.confirm(
        "Are you sure you want to set the MAX PRESSURE to 85 PSI?"
      );
      if (userConfirmed) {
        // setMaxPressBtnSelected(true);
        rpmValue = wtLastCmdTgtRpm;
        maxPressureValue = 85;
        send_maxpressure(parseInt(globalDev.id), parseFloat(rpmValue), parseFloat(maxPressureValue)).then((stopped) => {
          // debugger;
        });

      } else {
        return;
        // setMaxPressBtnSelected(false);
      }
    }

    if (wtLastCmdDischargeValue === 85) {
      userConfirmed = window.confirm(
        "Are you sure you want to reset the MAX PRESSURE to 50 PSI?"
      );
      if (userConfirmed) {
        //setMaxPressBtnSelected(false);
        maxPressureValue = 50;
        send_maxpressure(parseInt(globalDev.id), parseFloat(rpmValue), parseFloat(maxPressureValue)).then((stopped) => {
          // debugger;
        });

      } else {
        // setMaxPressBtnSelected(true);
        return;
      }
    }

  };


  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
    console.log("slider pct: " + event.target.value);
    localStorage.setItem(globalDev.id + "_throttle_pct", event.target.value);

    rpm = 600 + (event.target.value * 15);

    console.log("rpm value " + rpm);
    console.log("RPM " + rpmValue);
    //  if (rpm > 1000){    //temp top rpm = 1000
    //    rpm = 1000;
    //  }

    rpmValue = rpm;
    localStorage.setItem(globalDev.id + "_throttle_rpm", rpmValue);


    send_throttle(parseInt(globalDev.id), parseFloat(rpmValue), parseFloat(wtLastCmdDischargeValue)).then((stopped) => {
      // debugger;
    });
  };

  const resetSlider = (throttlePct) => {
    if (!throttlePct) {
      setSliderValue(0);
    } else {
      setSliderValue(throttlePct);
    }

    setResetKey(prevKey => prevKey + 1); // Assuming resetKey is a state initialized as a number
  };

  const getClassForConnectionType = (status) => {
    if (status === "Offline") {
      return "text-danger";
    } else if (status === "Online") {
      return "text-online";
    } else if (status === "Connected") {
      return "text-connected";
    } else {
      return "text-other";
    }
  };

  const handleTabSelect = (eventKey) => {
    if (!eventKey) {
      eventKey = "snapshot"
    }
    if (eventKey === "chart") {
      console.log("Chart tab selected");
      dispatch(setDeviceListUpdate(false));
      dispatch(setDeviceUpdate(true));
    } if (eventKey === "graph") {
      console.log("Graph tab selected");
      dispatch(setDeviceListUpdate(false));
      dispatch(setDeviceUpdate(true));
    } else if (eventKey === "snapshot") {
      console.log("Data tab selected");
      dispatch(setDeviceListUpdate(false));
      dispatch(setDeviceUpdate(true));
      handleDataTab();
    } else if (eventKey === "auto") {
      console.log("Auto tab selected");
      dispatch(setDeviceListUpdate(false));
      dispatch(setDeviceUpdate(true));
      handleAutoTab();  //wt
    }
  };

  return (
    <div style={{ padding: "10px 0", backgroundColor: "rgba(0,0,0,0.4)" }}>
      <PanelGroup id="key">
        <Panel key={device.id} id={`grid-${device.id}`}>
          <Panel.Heading
            className={device.status.toLowerCase()}
            style={{ padding: "0 10px" }}
          >
            <Panel.Title>
              <Grid fluid>
                <Row>
                  <Col xs={3} sm={2} md={2}>
                    <span>{device.icon}</span>
                  </Col>
                  <Col xs={5} sm={6} md={6}>
                    <h5>
                      <span className={"pump-name"}>{device.name}</span> <br />
                      <span className={"device-description"}>
                        {device.description}
                      </span>
                    </h5>
                  </Col>
                  <Col xs={4} className="text-right">
                    <span className={getClassForConnectionType(device.status)}>
                      {device.status}
                    </span>
                    <br />
                    <small className="text-muted">
                      {device.oldLocation
                        ? "GPS from " + device.lastFix.fromNow() + ", "
                        : ""}
                      {device.location.name} <br />
                      {device.isConnected
                        ? ""
                        : device.lastContact
                          ? device.lastContact.fromNow()
                          : ""}
                    </small>{" "}
                  </Col>
                </Row>
                {device.type == "Tricon" || device.type == "Mustang" ? (
                  <Row className="controls">
                    <Col xs={6} sm={2}>
                      <Button
                        fill
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {/* add for mustang here */}
                        {isMustangRW ? topRWRPM : topRpmValue}
                        <br /> RPM
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button
                        fill
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {isMustangRW ? topRWLoadPercent : topLoadPercentValue}
                        <br /> LOAD %
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button
                        fill
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {/* {device.prop_jfuelpsi} */}
                        {isMustangRW ? topRWFuelPSI : topFuelPsiValue}
                        <br />
                        FUEL PSI
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button
                        fill
                        bsStyle="success"
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {isMustangRW ? topRWCoolantTemp : topRateBpmValue}
                        <br />
                        {isMustangRW ? "COOLANT TEMP" : "RATE BPM"}
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button
                        fill
                        bsStyle="success"
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {isMustangRW ? topRWOilPressure : topSuctionPsiValue}
                        <br />
                        {isMustangRW ? "OIL PRESSURE" : "SUCTION PSI"}
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button
                        fill //bsStyle="success"
                        style={{
                          padding: "4px 4px",
                          height: 58,
                          lineHeight: 1.2,
                          fontSize: "medium",
                          fontWeight: "bold",
                          color: "#04FFF7",
                        }}
                      >
                        {" "}
                        {isMustangRW ? topRWFuelRate : topDischargePsiValue}
                        <br />
                        {isMustangRW ? "FUEL RATE" : "DISCHARGE PSI"}
                      </Button>
                    </Col>
                  </Row>
                ) : device.type == "J1939" ? (
                  <Row className="controls">
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_jrpm}
                        <br /> RPM
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_jloadPercent}
                        <br /> Load %
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_jfuelpsi}
                        <br /> Fuel PSI
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_jcoolant}
                        <br /> Coolant °F
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_joil}
                        <br /> Oil PSI
                      </Button>
                    </Col>
                    <Col xs={6} sm={2}>
                      <Button fill>
                        {" "}
                        {device.prop_jfuelrate}
                        <br /> Fuel Rate
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Grid>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <Tabs
                    defaultActiveKey="snapshot"
                    id={"snap_" + device.id}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    onSelect={handleTabSelect}
                  >

                    <Tab eventKey="snapshot" title="Data">                                       
                          <ResultsDisplay results={resultsAlert} />                      
                      {device.loading && !device.sections ? (
                        <div>Loading</div>
                      ) : (
                        ""
                      )}
                      {!device.loading && !device.sections ? (
                        <div>No Data Available</div>
                      ) : (
                        ""
                      )}
                      {shouldShowJ1939(device) ? (
                        <div>
                          <div className={"data-date-time"}>
                            <br></br>J1939 Data from{" "}
                            {device.j1939Time.fromNow()}
                          </div>

                          <div>
                            {/* Engine SPNs Table */}
                            <Row
                              xs={12}
                              style={{ padding: "0 10px", margin: "0" }}
                            >
                              <Col xs={12}>
                                <h3>Engine</h3>
                                {device.j1939Snapshot &&
                                  device.j1939Snapshot.spnsAry &&
                                  device.j1939Snapshot.spnsAry.engine &&
                                  device.j1939Snapshot.spnsAry.engine.length >
                                  0 ? (
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "50%" }}>Name</th>
                                        <th style={{ width: "50%" }}>
                                          Value (Unit)
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {device.j1939Snapshot.spnsAry.engine.map(
                                        (spn, index) => {
                                          return (
                                            <tr
                                              key={`engine-spn-${spn.id}-${index}`}
                                            >
                                              <td>{spn.name}</td>
                                              <td>{`${spn.value} ${spn.unit ? spn.unit : ""
                                                }`}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <div>No Engine Data Available</div>
                                )}
                              </Col>
                            </Row>
                            {/* Engine SPNs Table */}
                            <Row
                              xs={12}
                              style={{ padding: "0 10px", margin: "0" }}
                            >
                              <Col xs={12}>
                                <h3>Transmission</h3>
                                {device.j1939Snapshot &&
                                  device.j1939Snapshot.spnsAry &&
                                  device.j1939Snapshot.spnsAry.transmission &&
                                  device.j1939Snapshot.spnsAry.transmission.length >
                                  0 ? (
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "50%" }}>Name</th>
                                        <th style={{ width: "50%" }}>
                                          Value (Unit)
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {device.j1939Snapshot.spnsAry.transmission.map(
                                        (spn, index) => {
                                          return (
                                            <tr
                                              key={`transmisson-spn-${spn.id}-${index}`}
                                            >
                                              <td>{spn.name}</td>
                                              <td>{`${spn.value} ${spn.unit ? spn.unit : ""
                                                }`}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <div className="data-unavailable">No Transmission Data Available</div>
                                )}
                              </Col>
                            </Row>
                            {/* Pump SPNs Table */}
                            <Row
                              xs={12}
                              style={{ padding: "0 10px", margin: "0" }}
                            >
                              <Col xs={12}>
                                <h3>Pump</h3>
                                {device.j1939Snapshot &&
                                  device.j1939Snapshot.spnsAry &&
                                  device.j1939Snapshot.spnsAry.pump &&
                                  device.j1939Snapshot.spnsAry.pump.length > 0 ? (
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "50%" }}>Name</th>
                                        <th style={{ width: "50%" }}>
                                          Value (Unit)
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {device.j1939Snapshot.spnsAry.pump.map(
                                        (spn, index) => (
                                          <tr
                                            key={`pump-spn-${spn.id}-${index}`}
                                          >
                                            <td>{spn.name}</td>
                                            <td>{`${spn.value} ${spn.unit ? spn.unit : ""
                                              }`}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <div className="data-unavailable">No Pump Data Available</div>
                                )}
                              </Col>
                            </Row>

                            {/* Misc SPNs Table */}
                            <Row
                              xs={12}
                              style={{ padding: "0 10px", margin: "0" }}
                            >
                              <Col xs={12}>
                                <h3>Misc</h3>
                                {device.j1939Snapshot &&
                                  device.j1939Snapshot.spnsAry &&
                                  device.j1939Snapshot.spnsAry.misc &&
                                  device.j1939Snapshot.spnsAry.misc.length > 0 ? (
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "50%" }}>Name</th>
                                        <th style={{ width: "50%" }}>
                                          Value (Unit)
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {device.j1939Snapshot.spnsAry.misc.map(
                                        (spn, index) => (
                                          <tr
                                            key={`misc-spn-${spn.id}-${index}`}
                                          >
                                            <td>{spn.name}</td>
                                            <td>{`${spn.value} ${spn.unit ? spn.unit : ""
                                              }`}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <div className="data-unavailable">No CPU TEMP Data Available</div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={"data-date-time"}>
                        {device.triconTime
                          ? "Tricon Data from " + device.triconTime.fromNow()
                          : ""}
                      </div>
                      <Row
                        xs={1}
                        sm={2}
                        md={3}
                        style={{ padding: "0 10px", margin: "0" }}
                      >
                        {device.sections.map((s) => {
                          return (
                            <Col xs={12} sm={6} md={4} key={s.section}>
                              <h3>{s.name}</h3>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {s.values.map((v) => {
                                    return (
                                      <tr key={v.idx}>
                                        <td>
                                          {v.name.length > 20
                                            ? v.name.substring(0, 18) + "..."
                                            : v.name}
                                        </td>
                                        <td>{v.text}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </Col>
                          );
                        })}
                      </Row>
                    </Tab>
                    <Tab eventKey="chart" title="GRAPH">
                      <h5>{dataName}</h5>

                      <ChartView
                        device={device}
                        isMustang={isMustang}
                        deviceUpdateNum={deviceUpdateNum}
                      />
                    </Tab>
                    <Tab
                      eventKey="auto"
                      title="Auto"
                      disabled={!isMustang || !isKind}
                    >

                      <div className="wt-lights">
                        <div className="led-box">
                          <div
                            className={isAutoGreen ? "led-green" : "led-red"}
                          ></div>
                          <p>AUTO</p>
                        </div>
                        <div className="led-box">
                          <div
                            className={isOnGreen ? "led-green" : "led-red"}
                          ></div>
                          <p>ON</p>
                        </div>
                        <div className="led-box">
                          <div
                            className={isRunningGreen ? "led-green" : "led-red"}
                          ></div>
                          <p>RUNNING</p>
                        </div>
                      </div>
                      <div className="wt-center">
                        <div
                          className="wt-stop-btn"
                          onClick={() => handleStopBtnClick(true)}
                          hidden={!stopBtnState}
                        >
                          STOP
                        </div>
                        <div
                          className="wt-stopping-btn"
                          hidden={!stoppingBtnState}
                        >
                          STOPPING
                        </div>
                        <div
                          className="wt-enable-btn"
                          onClick={() => handleEnableBtnClick(true)}
                          hidden={!enableBtnState}
                        >
                          ENABLE
                        </div>
                        <div
                          className="wt-enable-disabled-btn"
                          hidden={!enableBtnDisabled}
                        >
                          ENABLE
                        </div>
                        <div
                          className="wt-start-btn"
                          onClick={() => handleStartBtnClick(true)}
                          hidden={!startBtnState}
                        >
                          START
                        </div>
                        <div
                          className="wt-starting-btn"
                          hidden={!startingBtnState}
                        >
                          STARTING
                        </div>
                      </div>
                      <div className="wt-disengage-center">
                        <div>
                          <button
                            type="button"
                            className="image-disengage-btn"
                            style={{
                              visibility: disengageBtnState
                                ? "visible"
                                : "hidden",
                            }} // Control visibility here
                            onClick={handleDisengageBtnClick}
                          >
                            <img src={disengage} height="45" width="250" />
                          </button>
                        </div>
                      </div>

                      <div>
                        <div className="discharge-info">
                          MAX PRESSURE: <span className="value-cyan">{wtLastCmdDischargeValue}</span> {'  '}&nbsp;&nbsp;&nbsp;&nbsp; ACTUAL PRESSURE: <span className="value-cyan">{wtReadingDischarge}</span>
                        </div>
                        <div className="wt-settings-btn-box">

                          <span>
                            <button
                              className={`two-line-button ${maxPressBtnSelected ? "active" : ""
                                }`}
                              onClick={handleMaxPressBtnClick}
                              disabled={maxPressBtnDisabled}
                            >
                              MAX PRESSURE<span>85 psi </span>
                            </button>
                          </span>
                          <span>
                            <button
                              className="two-line-button"
                              style={{ display: "visible" }}
                              disabled
                            >
                              SET RATE<span>120 bbl </span>
                            </button>
                          </span>
                        </div>
                        <div>
                          {/* <div className="wt-settings-btn-box">
                      <span><button className="two-line-button"style={{ display:'none' }}>MAX PRESSURE<span>CANCEL </span></button></span>
                        <span><button className="two-line-button"style={{ display:'none' }}>SET RATE<span>CANCEL </span></button></span>
                      </div> */}
                        </div>
                      </div>

                      <div className="slider-container">
                        <img
                          src={turtleImage}
                          alt="Turtle"
                          className="turtle-image left"
                        />
                        <div className="slidecontainer">
                          <div className="discharge-info">
                            REQUESTED RPM: <span className="value-cyan">{rpmValue}</span> {'  '}&nbsp;&nbsp;&nbsp;&nbsp; ACTUAL RPM: <span className="value-cyan">{wtReadingEngRpm}</span>
                          </div>
                          {/* {" "}
                             <p>ACTUAL RPM: <span className="value-cyan">{wtReadingEngRpm}</span></p> 
                             
                            
                          </div>
                          <div className="middle-paragraph">
                            <p>REQUESTED RPM: <span className="value-cyan">{rpmValue}</span></p>
                          </div> */}

                          <div className="left-paragraph">
                            <p>
                              {" "}
                              <span id="range" className="value-cyan">{sliderValue}%</span>
                            </p>
                          </div>

                          <input
                            key={resetKey}
                            type="range"
                            min="0"
                            max="100"
                            defaultValue={sliderValue}
                            className="slider"
                            id="wtThrottle"
                            onChange={handleSliderChange}
                            disabled={throttleDisabled}
                          />
                          <div className={sliderLabelClass}>THROTTLE</div>
                        </div>
                        <img
                          src={rabbitImage}
                          alt="Rabbit"
                          className="rabbit-image right"
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="detail" title="Detail">
                      {isMustang && (
                        <div>
                          <span className={"detail-tab-heading"}>
                            {"CAT 3456"}
                          </span>{" "}
                          <br />
                          <div>
                            <span className={"detail-tab-details"}>
                              {" "}
                              <b>OIL FILTER:</b> 1R-0716
                              <br />
                              <b>FUEL FILTER:</b> 1R-0749
                              <br />
                              <b>FUEL/WATER:</b> 513-4490
                              <br />
                              <b>FAN BELT:</b> GATES BX67
                              <br />
                              <b>ALTERNATOR BELT:</b> CAT B6880B0
                              <br />
                              <b>AIR FILTER:</b> 61-2509
                            </span>
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Grid>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default SelectedDevice;
